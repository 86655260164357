<template>
    <div class="menu">
        <router-link v-for="(item, index) in menuList" :key="item.name" :to="item.path" :active-class="getActive({ ...item }, index)" :class="$route.meta.parent == item.path ? 'active' : ''" @click.native="changeMenu(item)">
            <p>{{ item.name }}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "sideBar",
    data() {
        return {
            menuList: [
                { path: '/SettingConfig', name: '个人资料' },
                { path: '/order', name: '我的订单' },
                { path: '/feedback', name: '售后反馈' },
                { path: '/profit', name: '利润设置' },
                // {path: '/task', name: '我的任务'},
                { path: '/password', name: '修改密码' },
                { path: '/DeliveryAddress', name: '收货地址' },
                { path: '/policiesList', name: '优惠列表' },
                { path: '/noticeList', name: '公告列表' },
            ]
        };
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        changeMenu(item) {
            this.$store.commit('setCmenu', item.name)
            console.log('$route.meta.parent', this.$route.meta.parent)
        },
        getActive({ path, name }, item) {
            if (this.$route.path === path) {
                this.$store.commit('setCmenu', name)
            }
            return 'active'
        }
    },
}
</script>

<style scoped lang="scss">
.menu {
    box-sizing: border-box;
    min-width: 1.8rem;
    height: 4rem;
    background: #FFFFFF;
    background-size: 100%;
    margin-right: 0.2rem;
    border-radius: 0.04rem;
    padding-top: 0.28rem;
}

.menu a {
    position: relative;
    height: 0.2rem;
    line-height: 0.2rem;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 0.14rem;
    margin-bottom: 0.28rem;
    padding-left: 0.5rem;
    font-weight: 400;
}

.menu a.active {
    color: #4B83F0;
    border-left: 0.06rem solid #4B83F0;
    padding-left: 0.44rem;
    font-weight: 500;
}
</style>
