<template>
    <div class="layout">
        <top-bar/>
        <div class="container">
            <div class="top_text">
                <p>你的位置: <span @click="tab(0)">首页</span> / 个人中心</p>
            </div>
            <div class="content_individual">
                <div style="display: flex;align-items: flex-start;justify-content: space-between;height: 90%;">
                    <slide-bar/>
                    <div class="content_details">
                        <router-view :key="key"/>
                    </div>
                </div>
                <div class="bottom_text">{{ $store.state.text }}</div>
            </div>
        </div>

    </div>
</template>

<script>
import slideBar from '../components/sideBar'
import topBar from "../components/topBar";

export default {
    name: "Individual",
    components: {slideBar, topBar},
    data() {
        return {};
    },
    computed: {
        key() {
            return this.$route.path + Math.random();
        }
    },
    watch: {
    },
    methods: {
        /**
         * tab点击
         * */
        tab(i) {
            this.$store.commit('settab', 0)
            this.$router.push('/Index');
        },
    },
    mounted() {

        // this.$store.commit('settab', 2)
    },
}
</script>

<style scoped lang="scss">
.layout {
    width: 100vw;
    height: 100vh;
    background: #434655;
}

.container {
    width: 90%;
    margin: 0 auto;
    height: calc(100vh - 18%);

}
.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 50px;
}
.content_individual {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background: #434655;
    &::-webkit-scrollbar {
        display: none;
    }
    .content_details {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 0.04rem;
    }
}

.top_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 0.2rem;
}
</style>
