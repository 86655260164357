<template>
    <div class="top">
        <div class="top_left">
            <img class="top_left_logo" @click="logoClick" src="../assets/images/logo_con.png">
            <div class="login-content-tab">
                <router-link to="/Index">
                    <div @click="tab(0)" :class='["login-content-tab_list", $store.state.tab == 0 ? "login_content_hover" : "login_content_hover_none"]'>首页</div>
                </router-link>
                <!--          <router-link to="/Index"> <div class="login-content-tab_list" @click="tab(0)" :class="{login_content_hover:$store.state.tab==0}" >首页</div></router-link>-->
                <router-link to="/customize">
                    <div @click="tab(1)" :class='["login-content-tab_list", $store.state.tab == 1 ? "login_content_hover" : "login_content_hover_none"]'>装机</div>
                </router-link>
                <!--          <router-link to="/customize"> <div class="login-content-tab_list" @click="tab(1)" :class="{login_content_hover:$store.state.tab==1}" >装机</div></router-link>-->
            </div>
        </div>
        <div class="top_right">
            <div class="flex flex-child-center margin-100 no-margin-left no-margin-top no-margin-bottom">
                <el-form class="search-bar" ref="form" :inline="true" style="display: flex;align-items: center;">
                    <el-popover effect="light" trigger="hover" placement="bottom" width="auto">
                        <template #default>
                            <el-image style="width: 300px; height: 300px;display: block;" :src="mini_image" :fit="fit"></el-image>
                        </template>
                        <template #reference>
                            <el-image style="width: 50px; height: 50px;display: block;border-radius: 50%;" :src="mini_image" :fit="fit"></el-image>
                        </template>
                    </el-popover>
                    <div style="color: #FFFFFF;margin-right: 0.2rem;font-size: 0.12rem;margin-left: 0.2rem;" class="flex flex-child-center">
                        <div v-if="$store.state.turnOnOff" style="margin-right: 0.05rem;">价格开</div>
                        <div v-else style="margin-right: 0.05rem;">价格关</div>
                        <el-switch v-model="$store.state.turnOnOff" active-color="#ffffff" inactive-color="#E4E4E4" @change="turnOnOff()">
                        </el-switch>
                    </div>
                    <div style="color: #FFFFFF;margin-right: 0.2rem;font-size: 0.12rem;" class="flex flex-child-center">
                        <el-radio-group v-model="$store.state.show_type" size="large" @change="showType()" style="border:0.01rem solid #FFFFFF;border-radius: 0.05rem;">
                            <el-radio-button :label="0">图表</el-radio-button>
                            <el-radio-button :label="1">表格</el-radio-button>
                        </el-radio-group>
                    </div>
                    <el-form-item>
                        <el-input v-model="$store.state.keyword" placeholder="关键字搜索" clearable @keyup.enter.native="search"></el-input>
                        <img src="../assets/images/search.png" style="cursor: pointer;" @click="search">
                    </el-form-item>
                </el-form>
                <div class="gwc" @click="gwc">
                    <img src="../assets/images/gwc.png">
                    <div v-if="GwcNum > 0">{{ GwcNum }}</div>
                </div>
            </div>
            <div class="top_right_quit" style="cursor: pointer">
                <button slot="reference" class="top-button" @click="jump">
                    <!--            <p class="login_name">{{ $store.state.user.username }}</p>-->
                    <img :src="$store.state.user.avatar" alt="" class="top-button-image" v-if="$store.state.user.token">
                    <span style="color: #FFFFFF;" v-else>请登陆</span>
                </button>
            </div>
            <!--      <img src="../assets/images/top_close.png" alt="" @click="close">-->

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "topBar",
    data() {
        return {
            logoutShow: false,
            keyword: '',
            value: false,
            login_status: this.$store.state.user.token && this.$store.state.user.is_vip ? true : false,
            mini_image: this.$axios.defaults.baseURL + "/mini.png",
        };
    },
    computed: {
        route() {
            return this.$route
        },
        ...mapState(['GwcNum'])
    },

    watch: {
        '$store.state.tab'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.keyword = ''
                this.$store.commit('setKeyWord', this.keyword)
            }
        }
    },
    mounted() {
        if (window.location.hash.match('Index')) {
            this.$store.commit('settab', 0)
        } else if (window.location.hash.match('customize')) {
            this.$store.commit('settab', 1)
        } else {
            this.$store.commit('settab', 0)
        }
        if (this.$store.state.user.token) {
            this.$store.dispatch('GetGwc')
        }
        //购物车数量接口

    },

    methods: {
        showType() {
        },
        /**
         * logo点击
         * */
        logoClick() {
            this.keyword = ''
            this.$store.commit('setKeyWord', this.keyword)
            this.$store.commit('setSeach', '')
            this.$router.push('/Index');
            this.$store.commit('settab', 0)
        },
        /**
         * tab点击
         * */
        tab(i) {
            this.$store.commit('settab', i)
        },
        /**
         * 开关
         * */
        turnOnOff() {
            // this.$store.commit('setTurnOnOff', this.value)
        },
        /**
         * 搜索点击
         * */
        search() {
            this.$store.commit('setSeach', this.$store.state.keyword)
            this.$router.push('/Index');
            this.$store.commit('settab', 0)
        },
        /**
         * 跳转个人中心
         * */
        jump() {
            this.$router.push('/SettingConfig');
            this.$store.commit('settab', 0)
        },
        /**
         * 跳转购物车
         * */
        gwc() {
            this.$router.push('/shop');
            // this.$store.commit('settab', 2)
        },
    },

}
</script>

<style scoped lang="scss">
.el-tooltip__popper {
    padding: 5px;
    border: 1px solid #ccc !important;
}

.gwc {
    cursor: pointer;
    margin-left: 0.1rem;
    position: relative;
    display: flex;
    align-items: center;

    img {
        width: 0.3rem;
        height: 0.3rem;
    }

    div {
        width: 0.18rem;
        height: 0.18rem;
        background: #CF7AE2;
        border-radius: 0.09rem;
        font-size: 0.12rem;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 0.16rem;
        text-align: center;
        position: absolute;
        top: -0.08rem;
        right: -0.08rem;
    }
}

.login-content-tab {
    margin-left: 0.6rem;
    width: 1.4rem;
    display: flex;
    justify-content: space-around;
    font-size: 0.16rem;
    color: #DBE6FC;
}

.login-content-tab_list {}

.login_content_hover {
    color: #ffffff;
    border-bottom: 0.04rem solid #CF7AE2;
}

.login_content_hover_none {
    border-bottom: none !important;
}

.top_left div a {
    color: #DBE6FC;
}

.top-button-image {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 50%;
}

::v-deep .el-button--primary {
    background-color: #386AFC;
    border-color: #386AFC;
}

::v-deep .el-button--text {
    color: #386AFC;
}

::v-deep {
    .el-switch__core:after {
        background-color: #386AFC;
    }

    .el-input__inner:hover {
        border-color: #386AFC !important;
    }

    .el-input__inner {
        background: #FFFFFF;
        border-radius: 0.04rem;
        border: none !important;
        font-size: 0.16rem;
        color: #CBCBCB;
    }

    .el-form-item__content {
        border-radius: 0.04rem;
        background: #FFFFFF;
        line-height: 0.42rem;
        display: flex;
        align-items: center;

        img {
            width: 0.18rem;
            height: 0.18rem;
            margin-right: 0.1rem;
        }
    }

    .el-form-item {
        width: 2.5rem;
        margin-bottom: 0;
    }
}

.top {
    position: relative;
    height: 0.7rem;
    background: #4B83F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.8rem;
}

.top_left .top_left_logo {
    cursor: pointer;
    width: 1.8rem;
    height: 0.41rem;
}

.top_left,
.top_right {
    display: flex;
    align-items: center;
    //height: 0.7rem;
}



.top_left div b {
    display: block;
    height: 0.42rem;
    border-left: 0.01rem solid #b5b5b5;
    margin: 0 0.3rem;
}

.top_left div p {
    background: #7A40F2;
    height: 0.6rem;
    line-height: 0.6rem;
    width: 1.75rem;
    text-align: center;
    color: #fff;
    font-size: 0.2rem;
    border-radius: 0.04rem;
    cursor: pointer;
}

.top_left div p .other {
    background: #DEF5DD;
    color: #7A40F2;
}

.top_right .top_right_quit {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.18rem;
    color: #555;
}

.top_right div img {
    margin-left: 0.05rem;
}


.login_name {
    display: inline-block;
    font-size: 0.18rem;
}

.top-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}
</style>
